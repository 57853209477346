import React from "react";
import { InformationalTooltip } from "../Common/Tooltip";

import { Required, Description, Label, TextWrapper } from "./__styles__/Label";

export type LabelProps = {
  text: string;
  required?: boolean;
  description?: Maybe<string>;
  tabIndex?: number;
  htmlFor?: string;
  disabled?: boolean;
  tooltip?: string;
  style?: React.CSSProperties;
} & Parameters<typeof Label>["0"];

export default ({
  text,
  required = false,
  description,
  tooltip,
  tabIndex = 0,
  disabled = false,
  ...props
}: LabelProps) => (
  <Label tabIndex={tabIndex} {...props}>
    <TextWrapper disabled={disabled}>{text}</TextWrapper>
    {required && <Required>*</Required>}
    {description && (
      <Description disabled={disabled}>{description}</Description>
    )}
    {tooltip && <LabelTooltip text={tooltip} />}
  </Label>
);

const LabelTooltip = ({ text }: { text: string }) => {
  return <InformationalTooltip tooltipText={text} place="right" />;
};
