import React, { ReactNode } from "react";

import {
  Container,
  Content,
  Description,
  InputWrapper,
} from "./__styles__/ComplexRadio";

interface Props<Value> {
  checked: boolean;
  onChange: (value: Value) => void;
  value: Value;
  label: string | ReactNode;
  children: ReactNode;
  name: string;
  description?: Maybe<string>;
}

export default <Value extends string | number>({
  checked,
  onChange,
  label,
  children,
  value,
  description,
  name,
}: Props<Value>) => {
  return (
    <Container>
      <InputWrapper>
        <input
          type="radio"
          name={name}
          value={value}
          checked={checked}
          onChange={event => {
            onChange(event.target.value as Value);
          }}
        />
        <div>
          <div>{label}</div>
          <Description>{description}</Description>
        </div>
      </InputWrapper>
      <Content>{children}</Content>
    </Container>
  );
};
