import { omit } from "lodash";
import React, { useEffect, useReducer } from "react";
import "./__styles__/DateRange.scss";

import Datepicker from "./Datepicker";
import Radio from "./Radio";

type DateRangeType = {
  startDate: Maybe<Date | string>;
  endDate: Maybe<Date | string>;
};

const DateRange = ({
  onChange,
  value,
  disabled = false,
}: {
  onChange: (value: {
    startDate: Maybe<Date | string>;
    endDate: Maybe<Date | string>;
  }) => void;
  value: DateRangeType;
  disabled?: boolean;
}) => {
  const reducer = (
    state: DateRangeType & {
      rangeType: string;
    },
    action: {
      type: "setRangeType" | "setRange";
      rangeType?: string;
      startDate?: Date | string;
      endDate?: Date | string;
    }
  ) => {
    switch (action.type) {
      case "setRangeType":
        return {
          rangeType: action.rangeType!,
          startDate: null,
          endDate: null,
        };

      case "setRange":
        return {
          rangeType: "custom",
          startDate: action.startDate ?? state.startDate,
          endDate: action.endDate ?? state.endDate,
        };
      default:
        return {
          rangeType: "all",
          startDate: null,
          endDate: null,
        };
    }
  };

  const [state, dispatch] = useReducer(reducer, {
    ...value,
    rangeType: value.endDate || value.startDate ? "custom" : "all",
  });

  useEffect(() => {
    onChange(omit(state, "rangeType"));
  }, [state]);

  useEffect(() => {
    if (disabled) dispatch({ type: "setRangeType", rangeType: "all" });
  }, [disabled]);

  return (
    <>
      <Radio
        value={state.rangeType}
        onChange={value =>
          dispatch({ type: "setRangeType", rangeType: value! })
        }
        name="dates"
        options={[
          { value: "all", text: "All" },
          { value: "custom", text: "Custom" },
        ]}
        disabled={disabled}
      />

      {state.rangeType === "custom" && (
        <div styleName="date-range">
          <div styleName="field">
            <label styleName="label">Between</label>
            <Datepicker
              name="startDate"
              value={state.startDate}
              onChange={value =>
                dispatch({ type: "setRange", startDate: value })
              }
              size="smaller"
            />
          </div>
          <div styleName="field">
            <label styleName="label">And</label>
            <Datepicker
              name="endDate"
              value={state.endDate}
              onChange={value => dispatch({ type: "setRange", endDate: value })}
              size="smaller"
            />
          </div>
        </div>
      )}
    </>
  );
};

export default DateRange;
