import React, { ChangeEvent, InputHTMLAttributes } from "react";
import { ComponentProps } from "@stitches/react";

import { Checkbox } from "./__styles__/Checkbox";

interface Props {
  value?: boolean;
  name?: string;
  onChange: (value: boolean) => void;
  disabled?: boolean;
}

export default ({ value = false, name, onChange, disabled = false }: Props) => {
  const _handleChange = (evt: ChangeEvent<HTMLInputElement>) => {
    onChange(evt.target.checked);
  };

  return (
    <Checkbox
      type="checkbox"
      data-testid="checkbox"
      checked={!!value}
      onChange={_handleChange}
      disabled={disabled}
      id={name}
    />
  );
};

export const ReactHookFormCheckbox = React.forwardRef(
  (
    props: InputHTMLAttributes<HTMLInputElement> &
      ComponentProps<typeof Checkbox>,
    ref: React.Ref<HTMLInputElement>
  ) => {
    return (
      <Checkbox type="checkbox" data-testid="checkbox" ref={ref} {...props} />
    );
  }
);
