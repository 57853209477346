import React, { ReactNode } from "react";
import { Fieldset, Error } from "./__styles__/Wrapper";

import Label, { LabelProps } from "./Label";

export type WrapperProps = {
  name: string;
  children: ReactNode;
  label?: undefined | Maybe<string>;
  error?: Maybe<string>;
  addon?: ReactNode;
  expandWithError?: boolean;
  tooltip?: ReactNode;
  compactLabel?: LabelProps["compact"];
  labelTabIndex?: LabelProps["tabIndex"];
  labelSize?: LabelProps["size"];
} & Pick<LabelProps, "description" | "disabled" | "required">;

export default ({
  label,
  compactLabel = false,
  name,
  error,
  addon,
  labelTabIndex,
  children,
  required,
  description,
  disabled,
  expandWithError = true,
  tooltip,
  labelSize,
}: WrapperProps) => {
  return (
    <Fieldset hasTooltip={!!tooltip} error={!!error}>
      {!!label && (
        <Label
          text={label}
          compact={compactLabel}
          htmlFor={name}
          tabIndex={labelTabIndex}
          required={required}
          description={description}
          disabled={disabled}
          size={labelSize}
        />
      )}
      {tooltip}
      {addon}
      {children}
      {!!error && <Error expandWithError={expandWithError}>{error}</Error>}
    </Fieldset>
  );
};
