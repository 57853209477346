import React, { ComponentProps } from "react";

import Text from "./Text";

export const mask = (value: string) => {
  const onlyNumbers = (value.match(/\d/g) || []).join("");
  const parts = [
    onlyNumbers.slice(0, 3),
    onlyNumbers.slice(3, 6),
    onlyNumbers.slice(6),
  ];

  return `${!!parts[1] ? "(" + parts[0] + ")" : parts[0]}${
    parts[1] ? " " : ""
  }${parts[1]}${parts[2] ? "-" : ""}${parts[2]}`;
};

type PhoneProps = {
  onChange: (value: string) => void;
} & ComponentProps<typeof Text>;

export default ({ onChange, ...props }: PhoneProps) => {
  return <Text {...props} type="tel" onChange={v => onChange(mask(v))} />;
};
