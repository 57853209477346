import React, { ReactNode } from "react";
import {
  FieldPath,
  FieldValues,
  useController,
  UseControllerProps,
} from "react-hook-form";

import { Error, Option, OptionWrapper } from "./__styles__/Radio";

export interface RadioProps {
  name: string;
  value: string;
  options: Array<{ value: string; text: ReactNode; children?: ReactNode }>;
  disabled?: boolean;
  error?: Maybe<string>;
  className?: string;
  hasBackground?: boolean;
  onChange: (value: Maybe<string>) => void;
  required?: boolean;
  fontSize?: "default" | "normal";
  fontColor?: "black";
  autoFocus?: boolean;
}

function Radio({
  name,
  value,
  onChange,
  options,
  disabled = false,
  error,
  required = false,
  className,
  hasBackground = false,
  fontSize = "default",
  fontColor,
  autoFocus,
}: RadioProps) {
  return (
    <OptionWrapper className={className} hasBackground={hasBackground}>
      {options.map(({ value: optionValue, text, children }, idx) => (
        <div key={`opt-${name}-${idx}`}>
          <Option
            hasBackground={hasBackground}
            fontSize={fontSize}
            fontColor={fontColor}
          >
            <input
              type="radio"
              value={optionValue}
              name={name}
              onChange={evt => onChange(evt.target.value)}
              checked={optionValue === value}
              id={`opt-${name}-${idx}`}
              disabled={disabled}
              onClick={() => {
                // the weird `as any` here is needed because the
                // type-checker isn't smart enough to realize that,
                // if `!required` is true, onChange has to be a method
                // that handles null given the function overloads above
                optionValue === value && !required
                  ? onChange(null as any)
                  : null;
              }}
              autoFocus={autoFocus && idx === 0 ? true : false}
            />
            <label htmlFor={`opt-${name}-${idx}`}>{text}</label>
          </Option>
          {children}
        </div>
      ))}
      {error ? <Error hasBackground={hasBackground}>{error}</Error> : null}
    </OptionWrapper>
  );
}

type ReactHookFormRadioProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
> = UseControllerProps<TFieldValues, TName> &
  Omit<RadioProps, "value" | "onChange">;

export const ReactHookFormRadio = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
>({
  control,
  disabled,
  name,
  options,
  required = false,
  autoFocus,
  ...props
}: ReactHookFormRadioProps<TFieldValues, TName>) => {
  const { field, fieldState } = useController({
    control,
    name,
    rules: { required },
  });
  return (
    <Radio
      name={name}
      value={field.value}
      error={fieldState.error?.message}
      options={options}
      onChange={field.onChange}
      required={required}
      disabled={disabled}
      autoFocus={autoFocus}
      {...props}
    />
  );
};

export default Radio;
